.card {
  background-color: white;
}

.title {
  font-family: Greycliff CF, var(--mantine-font-family);
  font-weight: 700;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: var(--mantine-radius-md);
  height: rem(90px);
  background-color: light-dark(
    var(--mantine-color-white),
    var(--mantine-color-dark-7)
  );
  transition: box-shadow 150ms ease, transform 100ms ease;

  @mixin hover {
    box-shadow: var(--mantine-shadows-md);
    transform: scale(1.05);
  }
}
